import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageIntro from "../components/common/pageIntro/pageIntro"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import GenericComponents from "../components/generic/genericComponents/genericComponents"
import BlockBooking from "../components/common/blockBooking/blockBooking"

export default function GenericContent({ data: { nodeGeneric }, pageContext }) {
  const {
    title,
    field_seo,
    relationships: {
      generic_introduction: pageIntro,
      generic_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      generic_components: components,
    },
  } = nodeGeneric

  const intl = useIntl()

  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )
  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageGeneric">
      <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      <Breadcrumbs
        hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
        pageTitle={title}
      />
      {pageIntro && <PageIntro slice={pageIntro} />}
      <GenericComponents components={componentsFiltered} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeGeneric(id: { eq: $id }) {
      ...genericFields
    }
  }
`
