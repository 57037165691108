import * as React from "react"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./pageIntro.scss"
import { Container, Row, Col } from "react-bootstrap"
import Title from "../title"
import StyledButton from "../styledButton"
import {Parallax} from "react-scroll-parallax"


const PageIntro = props => {
  const slice = props.slice

  let classSpacing = "blk-introduction"

  if (
    slice.relationships.introduction_medias &&
    !slice.relationships.introduction_medias.length
  ) {
    classSpacing = "blk-introduction blk-spacing"
  }

  const medias = slice.relationships.introduction_medias
    ? slice.relationships.introduction_medias
    : ""
  const mediaElements = medias.map(function (media, index) {
    // const imageExt = ["png", "jpg", "jpeg"]
    // const fileType = media.name.split(".").pop()

    if (media.internal.type === "media__image") {
      return (
        <GatsbyImage
          image={getImage(
            media.relationships.field_image?.gatsbyImage
          )}
          alt={media.field_image.alt}
        />
      )
    } else {
      return (
        <video autoPlay={1} loop muted>
          <source
            src={
              media.relationships.field_media_video_file?.publicURL
            }
            type="video/mp4"
          />
        </video>
      )
    }
  })

  let buttonStyle

  if (props.buttonStyle) {
    buttonStyle = props.buttonStyle
  } else {
    buttonStyle = "link"
  }

  return (
    <section className={classSpacing}>
      <Container className={medias.length > 1 ? "twoImg" : "singleImg"}>
        <Row className={medias.length > 0 ? "justify-content-center alignLeft" : "justify-content-center alignCenter"}>
          <Col md={6} xs={12}>
            <span className="caption animTxt">
              <span>{slice.introduction_caption}</span>
            </span>
            <Title text={slice.introduction_title} />
            {slice.introduction_description && <div className="description animTxt animTxt_3">
              {parse(slice.introduction_description.value)}
            </div>}
            <br />
            <div className="btnList">
              {slice?.link_de && (
                <Link
                  to={slice?.link_de?.url}
                  target="_blank"
                  className="simpleLink germanLink"
                >
                  <span>{slice?.introduction_link?.title}</span>
                </Link>
              )}
              {slice.introduction_link && (
                <Link target={"_blank"} className="simpleLink englishLink" to={slice.introduction_link.url}>
                  <span>{slice.introduction_link.title}</span>
                </Link>
              )}

              {slice.event_intro_cta && props.isOffer && (
                <StyledButton
                  type={buttonStyle}
                  title={slice.event_intro_cta.title}
                  path={slice.event_intro_cta.url}
                />
              )}
            </div>
          </Col>
          <Col md={6} xs={12}>
            {mediaElements.length > 0 && (
              mediaElements.map((media, index) => {
                return (
                  <Parallax key={index} speed={index === 0 ? -10 : 10}>
                    {media}
                  </Parallax>
                )
              })
            )}
          </Col>
        </Row>

      </Container>
    </section>
  )
}

export const query = graphql`
  fragment intro on paragraph__introduction {
    introduction_title
    introduction_caption
    introduction_description {
      processed
      value
    }
    link_de {
      url
      title
    }
    introduction_link {
      title
      uri
      url
    }
    event_intro_cta {
      title
      url
    }
    relationships {
      introduction_medias {
        ... on media__image {
          internal {
            type
          }
          field_image {
            alt
          }
          name
          relationships {
            ...getImage
          }
        }
      }
    }
  }
`

export default PageIntro
