import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./breadcrumbs.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import {
  HOTEL_TAXONOMY_HERITAGE_AWALI_ID,
  HOTEL_TAXONOMY_HERITAGE_LE_TELFAIR_ID,
  HOTEL_TAXONOMY_HERITAGE_RESORT_ID,
} from "../../../constants"
import { getLanguageSwitcherDefaultUrlByLocale } from "../../../services/intl.service"

const Breadcrumbs = ({ hotelTaxonomyDrupalId, pageTitle, localeBlog }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          title
        }
      }
      mainMenuEn: allMenuLinkContentMain(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...mainMenuFields
      }
      mainMenuFr: allMenuLinkContentMain(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...mainMenuFields
      }
    }
  `)

  let allMenus, allMenuEN, allMenuFR

  switch (hotelTaxonomyDrupalId) {
    // case HOTEL_TAXONOMY_HERITAGE_LE_TELFAIR_ID:
    //   allMenuEN = data.telfairEn
    //   allMenuFR = data.telfairFr
    //   break
    // case HOTEL_TAXONOMY_HERITAGE_AWALI_ID:
    //   allMenuEN = data.awaliEn
    //   allMenuFR = data.awaliFr
    //   break
    // case HOTEL_TAXONOMY_HERITAGE_RESORT_ID:
    //   allMenuEN = data.resortEn
    //   allMenuFR = data.resortFr
    //   break
    default:
      allMenuEN = data.mainMenuEn
      allMenuFR = data.mainMenuFr
      break
  }

  switch (intl.locale) {
    case "fr":
      allMenus = allMenuFR.nodes
      break
    default:
      allMenus = allMenuEN.nodes
  }

  const hashTable = {}
  allMenus.forEach(
    menu => (hashTable[menu.drupal_id] = { ...menu, childNodes: [] })
  )

  const menuTree = []
  allMenus.forEach(menu => {
    if (menu.drupal_parent_menu_item) {
      const stripped_drupal_id = menu.drupal_parent_menu_item.replace(
        "menu_link_content:",
        ""
      )
      hashTable[stripped_drupal_id]?.childNodes?.push(hashTable[menu.drupal_id])
    } else {
      menuTree.push(hashTable[menu.drupal_id])
    }
  })

  const breadcrumbs = []
  const breadcrumbTrail = title => {
    // Check in first level
    const menu = menuTree.find(menu => menu.title === title)
    if (menu) {
      breadcrumbs.push(menu)
    }

    // Check in second level
    menuTree.forEach(menu => {
      if (menu.childNodes) {
        const childMenu = menu.childNodes.find(menu => menu.title === title)
        if (childMenu) {
          breadcrumbs.push(menu)
          breadcrumbs.push(childMenu)
        }
      }
    })

    // Check in third level
    menuTree.forEach(menu => {
      if (menu.childNodes) {
        menu.childNodes.forEach(childMenu => {
          if (childMenu.childNodes) {
            const grandChildMenu = childMenu.childNodes.find(
              menu => menu.title === title
            )
            if (grandChildMenu) {
              breadcrumbs.push(menu)
              breadcrumbs.push(childMenu)
              breadcrumbs.push(grandChildMenu)
            }
          }
        })
      }
    })

    const currentUrl = typeof window !== "undefined" ? window.location.href : ""

    if (breadcrumbs.length === 0) {
      if (localeBlog) {
        return [
          {
            title: "Blog",
            link: {
              url: localeBlog.toLowerCase() === "en" ? "/blog" : "/blog/fr",
            },
          },
          {
            title: title,
            link: {
              url: currentUrl,
            },
          },
        ]
      } else {
        return [
          {
            title: title,
            link: {
              url: currentUrl,
            },
          },
        ]
      }
    }

    return breadcrumbs
  }

  const trail = breadcrumbTrail(pageTitle)
  return (
    <>
      {trail.length > 1 && (
        <section className="breadcrumb">
          <Container>
            <Row>
              <Col>
                <ul>
                  <li>
                    <Link
                      to={getLanguageSwitcherDefaultUrlByLocale(intl.locale)}
                    >
                      {intl.formatMessage({
                        id: "menu.home.title",
                      })}
                    </Link>
                  </li>
                  {trail.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.link ? (
                          <Link to={item.link.url}>{item.title}</Link>
                        ) : (
                          <span>{item.title}</span>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default Breadcrumbs
